import { useCallback, useEffect, useState } from 'react';
import { useObjectMemo } from '@monkvision/common';

enum DeviceOrientationPermissionResponse {
  GRANTED = 'granted',
  DENIED = 'denied',
}

interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
  requestPermission?: () => Promise<DeviceOrientationPermissionResponse>;
}

export interface DeviceOrientationHandle {
  isPermissionGranted: boolean;
  alpha: number;
  smooth1: number;
  smooth2: number;
  smooth3: number;
  requestCompassPermission: () => Promise<void>;
  resetOrientation: () => void;
}

function lowPass(oldVal: number, newVal: number, smoothing: number): number {
  let smooth;
  if (oldVal - newVal < -180){
    smooth = oldVal - smoothing * (oldVal + 360 - newVal);
    if (smooth < 0){
      smooth = smooth + 360;
    }
  } else if (oldVal - newVal > 180){
    smooth = oldVal + (360 + newVal - oldVal) * smoothing;
    if (smooth > 360){
      smooth = smooth - 360;
    }
  }
  else {
    smooth = oldVal + smoothing * (newVal - oldVal);
  }
  return smooth;
}

export function useDeviceOrientation(): DeviceOrientationHandle {
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [alpha, setAlpha] = useState(0);
  const [smooth1, setSmooth1] = useState(0);
  const [smooth2, setSmooth2] = useState(0);
  const [smooth3, setSmooth3] = useState(0);

  const handleDeviceOrientationEvent = useCallback((event: DeviceOrientationEvent) => {
    const alpha = event.alpha ?? 0;
    setAlpha(alpha);
    setSmooth1((old) => lowPass(old, alpha, 0.15));
    setSmooth2((old) => lowPass(old, alpha, 0.30));
    setSmooth3((old) => lowPass(old, alpha, 0.50));
  }, []);

  const requestCompassPermission = useCallback(async () => {
    if (DeviceOrientationEvent) {
      const { requestPermission } = DeviceOrientationEvent as unknown as DeviceOrientationEventiOS;
      if (typeof requestPermission === 'function') {
        const response = await requestPermission();
        if (response !== DeviceOrientationPermissionResponse.GRANTED) {
          throw new Error('Device orientation permission request denied.');
        }
      }
    }
    setIsPermissionGranted(true);
  }, []);

  const resetOrientation = useCallback(() => {
    if (isPermissionGranted) {
      setAlpha(0);
      setSmooth1(0);
      setSmooth2(0);
      setSmooth3(0);
      window.removeEventListener('deviceorientation', handleDeviceOrientationEvent);
      window.addEventListener('deviceorientation', handleDeviceOrientationEvent);
    }
  }, [isPermissionGranted, handleDeviceOrientationEvent]);

  useEffect(() => {
    window.addEventListener('deviceorientation', handleDeviceOrientationEvent);

    return () => {
      window.removeEventListener('deviceorientation', handleDeviceOrientationEvent);
    };
  }, [handleDeviceOrientationEvent]);

  return useObjectMemo({ isPermissionGranted, alpha, smooth1, smooth2, smooth3, resetOrientation, requestCompassPermission });
}
