import { Button, DynamicSVG, Icon } from '@monkvision/common-ui-web';
import { useTranslation } from 'react-i18next';
import { useLoadingState, useResponsiveStyle } from '@monkvision/common';
import { styles, useVideoCapturePermissionsStyles } from './VideoCapturePermissions.styles';
import { monkLogoSVG } from '../../assets/logos.asset';
import { useDeviceOrientation } from './useDeviceOrientation';

/**
 * Props accepted by the VideoCapturePermissions component.
 */
export interface VideoCapturePermissionsProps {
  /**
   * Callback called when the user has successfully granted the required permissions to the app.
   */
  onSuccess?: () => void;
}

/**
 * Component displayed in the Permissions view of the video capture. Used to make sure the current app has the proper
 * permissions before moving forward.
 */
export function VideoCapturePermissions() {
  const { t } = useTranslation();
  const loading = useLoadingState();
  const {
    isPermissionGranted,
    alpha,
    smooth1,
    smooth2,
    smooth3,
    requestCompassPermission,
    resetOrientation,
  } = useDeviceOrientation();
  const {
    logoProps,
    permissionIconProps,
    titleStyle,
    permissionTitleStyle,
    permissionDescriptionStyle,
  } = useVideoCapturePermissionsStyles();

  const handleConfirm = () => {
    loading.start();
    requestCompassPermission()
      .then(() => loading.onSuccess())
      .catch((err) => loading.onError(err));
  };

  return (
    <div style={styles['container']}>
      <DynamicSVG svg={monkLogoSVG} {...logoProps} />
      <div style={titleStyle}>{t('video.permissions.title')}</div>
      <div style={styles['permissionsContainer']}>
        <div style={styles['permission']}>
          <Icon icon='camera-outline' style={styles['permissionIcon']} {...permissionIconProps} />
          <div style={styles['permissionLabels']}>
            <div style={permissionTitleStyle}>{t('video.permissions.camera.title')}</div>
            <div style={permissionDescriptionStyle}>
              {t('video.permissions.camera.description')}
            </div>
          </div>
        </div>
        <div style={styles['permission']}>
          <Icon icon='compass-outline' style={styles['permissionIcon']} {...permissionIconProps} />
          <div style={styles['permissionLabels']}>
            <div style={permissionTitleStyle}>{t('video.permissions.compass.title')}</div>
            <div style={permissionDescriptionStyle}>
              {t('video.permissions.compass.description')}
            </div>
          </div>
        </div>
        {loading.error && <div>Error : {JSON.stringify(loading.error, null, 2)}</div>}
        {isPermissionGranted && <div>Alpha : {alpha}</div>}
        {isPermissionGranted && <div>Smooth 1 : {smooth1}</div>}
        {isPermissionGranted && <div>Smooth 2 : {smooth2}</div>}
        {isPermissionGranted && <div>Smooth 3 : {smooth3}</div>}
        {isPermissionGranted && <Button onClick={resetOrientation}>Reset</Button>}
      </div>
      <div style={styles['confirmContainer']}>
        <Button onClick={handleConfirm} disabled={isPermissionGranted} loading={loading}>
          {t('video.permissions.confirm')}
        </Button>
      </div>
    </div>
  );
}
